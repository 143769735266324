import "./App.css";
import logo from "./assets/logo/aryahub_logo_light.svg";
import hand from "./assets/illustration/hand_arrows.svg";
import wheel1 from "./assets/illustration/wheel1.svg";
import wheel2 from "./assets/illustration/wheel2.svg";
import wheel3 from "./assets/illustration/wheel3.svg";
import arrowBadge from "./assets/icons/arrow-badge.svg";

function App() {
  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
      </header>
      <main className="main">
        <div className="illustration">
          <img src={wheel1} id="wheel1" className="wheel" alt="small wheel" />
          <img src={wheel2} id="wheel2" className="wheel" alt="big wheel" />
          <img src={wheel3} id="wheel3" className="wheel" alt="medium wheel" />
          <img src={hand} id="hand" alt="hand" />
        </div>
        <section className="title">
          <h1 id="websiteTitle">Website</h1>
          <h1 id="underConstruction">Under Construction</h1>
        </section>
        <p className="paragraph">
          Exciting things are on the way! Stay tuned and follow our LinkedIn for
          updates and information.
        </p>
        <a className="link" href="https://www.linkedin.com/company/aryahub/">
          <button className="button" type="button">
            <img className="arrow" src={arrowBadge} alt="arrow" />
            <span id="linkedin">LinkedIn page</span>
          </button>
        </a>
      </main>
      <footer>
        <span className="credit">© Aryahub 2024</span>
      </footer>
    </div>
  );
}

export default App;
